import React from 'react'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import Grid from '@mui/material/Grid';
import config from "config";
import CircularIntegration from './CircularIntegration';

export default function CreateForm(props) {
    const [invalidLink, setInvalidValidLink] = React.useState(false);
    const [helperText, setHelperText] = React.useState("");

    const handleGuardarButton = (setSuccessFunc, setLoadingFunc) => {
        // eslint-disable-next-line
        var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
        if(!props.linkToRedirect){
            setInvalidValidLink(true)
            setHelperText("El campo no puede estar vacío.")
            setLoadingFunc(false)
        }
        else if(!httpRegex.test(props.linkToRedirect)){
            setInvalidValidLink(true)
            setHelperText("El formato del link no es correcto, debe incluir (http:// o https://).")
            setLoadingFunc(false)
        }
        else {
            setInvalidValidLink(false)
            setHelperText("")
            props.handleGuardarButton(setSuccessFunc, setLoadingFunc)
        }
    }

    return (
        <Box sx={{ flexGrow: 1 }} style={{backgroundColor: "white", borderRadius: "4px", marginBottom: "15px", paddingBottom: "10px", paddingLeft: "10px", paddingRight: "10px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4} xl={4}>
                        <TextField
                            id="main-url-input"
                            label="URL"
                            defaultValue={`${config.app.url}/u/algun-codigo-nuevo`}
                            InputProps={{
                                readOnly: true,
                            }}
                            disabled={true}
                            size="small"
                            fullWidth
                        />
                </Grid>
                <Grid item xs={12} md={8} xl={8}>
                        <TextField 
                            id="newLinkToRedirect" 
                            label="Nueva Redireccion:" 
                            variant="outlined"
                            size="small"
                            style={{backgroundColor: "white"}}
                            defaultValue={props.linkToRedirect}
                            value={props.linkToRedirect}
                            onChange={(e) => {props.handleInputChange(e)}}
                            fullWidth
                            error={invalidLink}
                            helperText={helperText}
                        />
                </Grid>
                <Grid item xs={1} md={1} xl={1}>
                    <CircularIntegration
                        handleGuardarButton={handleGuardarButton}
                        setloading={props.setLoading}
                    />
                </Grid>
            </Grid>
            <div>
                
            </div>
        </Box>
    );
}