import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function BasicComponents() {
  return (
    <>
      <div className="section section-basic-components">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" lg="5" md="7">
              <h2 className="title">Facilidad a tu alcance</h2>
              <h6 className="category">Haciendo uso de la tecnologia</h6>
              <h5 className="description" style={{textAlign: "justify"}}>
                <b>
                  El NFC es una solución digital que permite
                  conectar a dispositivos de manera inalámbrica, es muy
                  sencilla de utilizar y ademas es muy segura!
                  <br></br>
                  <br></br>
                  Permite una mayor comodidad y flexibilidad al usuario para
                  consultar la información que se quiera desplegar
                </b>
              </h5>
            </Col>
            <Col lg="6" md="12">
              <div className="image-container">
                <img
                  alt="..."
                  className="components-macbook"
                  src={require("assets/img/nfc1.png").default}
                  style={{maxHeight: "350px"}}
                ></img>
                <img
                  alt="..."
                  className="table-img"
                  src={
                    require("assets/img/presentation-page/InstaCarta.png").default
                  }
                ></img>
                {/* <img
                  alt="..."
                  className="share-btn-img"
                  src={
                    require("assets/img/presentation-page/share-btn.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="coloured-card-btn-img"
                  src={
                    require("assets/img/presentation-page/coloured-card-with-btn.jpg")
                      .default
                  }
                ></img> */}
                {/* <img
                  alt="..."
                  className="coloured-card-img"
                  src={
                    require("assets/img/presentation-page/coloured-card.jpg")
                      .default
                  }
                ></img>
                <img
                  alt="..."
                  className="social-img"
                  src={
                    require("assets/img/presentation-page/social-row.jpg")
                      .default
                  }
                ></img> */}
                {/* <img
                  alt="..."
                  className="linkedin-btn-img"
                  src={
                    require("assets/img/presentation-page/linkedin-btn.jpg")
                      .default
                  }
                ></img> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default BasicComponents;
