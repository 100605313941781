import React, { Component } from "react";
import { Redirect } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Label,
  Container,
  Col,
  Row,
} from "reactstrap";

// core components
import DropdownScrollNavbar from "components/Navbars/DropdownScrollNavbar.js";
import Footer from "components/Footers/Footer.js";
// Custom
import { Auth } from "aws-amplify";
import FormErrors from "./FormErrors";
import Validate from "./FormValidation";
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';

function LoginPage(props) {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <DropdownScrollNavbar />
      <div className="page-header header-filter" filter-color="black">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/login.jpg").default + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="5">
                <Card className="card-login card-plain">
                  <Form action="" className="form" method="">
                    <CardHeader className="text-center">
                      <div className="logo-container" style={{width: "300px", backgroundColor: "transparent"}}>
                        <img
                          alt="..."
                          src={require("assets/img/InstaCarta_Texto.png").default}
                        ></img>
                        {props.loading && <center>
                          <CircularProgress style={{width: "50px", height: "50px", marginTop: "30px"}}/>
                        </center>}
                      </div>
                    </CardHeader>
                    <CardBody>
                    <FormErrors formerrors={props.errors}/>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="username"
                          placeholder="Usuario..."
                          type="text"
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          onChange={(e) => {props.onInputChange(e)}}
                          onKeyPress={(e)=>{props.handleEnterInput(e)}}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          id="password"
                          placeholder="Contraseña..."
                          type={props.passwordReveal}
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                          onChange={(e) => {props.onInputChange(e)}}
                          onKeyPress={(e)=>{props.handleEnterInput(e)}}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                          <div style={{marginLeft: "30px", color: "white"}}>
                            <Input type="checkbox" onClick={() => {props.handlePasswordReveal()}}/>
                            <Label check>
                              Mostrar contraseña
                            </Label>
                          </div>
                      </InputGroup>
                    </CardBody>
                    <CardFooter className="text-center">
                      <Button
                        block
                        className="btn-round"
                        color="info"
                        href="#pablo"
                        onClick={(e) => {props.handleSubmit(e)}}
                        size="lg"
                      >
                        Ingresar
                      </Button>
                    </CardFooter>
                    {/* <div className="pull-left">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Crear Cuenta
                        </a>
                      </h6>
                    </div> */}
                    {/* <div className="pull-right">
                      <h6>
                        <a
                          className="link footer-link"
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                        >
                          Need Help?
                        </a>
                      </h6>
                    </div> */}
                  </Form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </>
  );
}


class LogIn extends Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      errors: {
        cognito: null,
        blankfield: false,
      },
      loading: false,
      status: "",
      passwordReveal: "password"
    };
  }

  clearErrorState = () => {
    this.setState({
      errors: {
        cognito: null,
        blankfield: false,
      }
    });
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    // Form validation
    this.clearErrorState();
    const error = Validate(event, this.state);
    if (error) {
      this.setState({
        errors: { ...this.state.errors, ...error }
      });
    }

    // AWS Cognito integration here
    try{
      this.setState({loading: true});
      this.setState({status: "Iniciando Sesión"});
      const user = await Auth.signIn(this.state.username, this.state.password);
      const session = await Auth.currentSession();
      const jwt = await session.getIdToken().getJwtToken()
      axios.defaults.headers.common['Id-Token'] = jwt;
      this.setState({loading: false});
      this.props.auth.setLoginVariables(user, jwt, true);
      
    }catch(error){
      this.setState({loading: false});
      let err = null;
      !error.message ? err = {"message": error} : err = error;
      this.setState({
        errors: {
          ...this.state.errors,
          cognito: err
        }
      })
    }
  };

  onInputChange = (e) => {
    let name = e.target.id
    this.setState({[name]: e.target.value})
    
  };

  handleEnterInput = (event) =>{
    if(event.key === 'Enter'){
      this.handleSubmit(event);
    }
  } 

  handlePasswordReveal = () =>{
    let passwordReveal = this.state.passwordReveal
    if(passwordReveal === "password"){
      passwordReveal = "text"
    } else{
      passwordReveal = "password"
    }
    this.setState({passwordReveal: passwordReveal})
  }

  render() {
    if (this.props.auth.isAuthenticated === true) {
      return <Redirect to='/' />
    }
    return (
      <div>
        <LoginPage 
        handleSubmit={this.handleSubmit}
        onInputChange={this.onInputChange}
        errors={this.state.errors}
        handleEnterInput={this.handleEnterInput}
        loading={this.state.loading}
        passwordReveal={this.state.passwordReveal}
        handlePasswordReveal={this.handlePasswordReveal}
        >

        </LoginPage>
      </div>
    );
  }
}


export default LogIn;
