import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Icons
import DeleteIcon from '@mui/icons-material/Delete';

export default function DeleteConfirmationDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [deleteInProgress, setDeleteInProgress] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const setDeleteInProgressFunc = (bool) => {
    setDeleteInProgress(bool)
  }

  const handleEliminar = () => {
    if(!deleteInProgress){
      setDeleteInProgress(true);
      const row = {
        link_id: props.linkId
      }
      props.handleRowDelete(row, setDeleteInProgressFunc);
      setOpen(false);
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <DeleteIcon 
        className="delete-buttons" 
        onClick={handleClickOpen}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"¿Eliminar esta redirección?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="redirection-text">{props.url}</div>
            <br></br>
            Esta acción es permanente.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleEliminar} autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}