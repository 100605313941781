/*!

=========================================================
* Now UI Kit PRO React - v1.5.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2021 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component, Suspense} from "react";
import { Router, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages
import AboutUs from "views/examples/AboutUs.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs.js";
import Ecommerce from "views/examples/Ecommerce.js";
import Index from "views/Index.js";
import LandingPage from "views/examples/LandingPage.js";
import LoginPage from "views/examples/LoginPage.js";
import NucleoIcons from "views/NucleoIcons.js";
import Presentation from "views/Presentation.js";
import Pricing from "views/examples/Pricing.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Sections from "views/Sections.js";
import SignupPage from "views/examples/SignupPage.js";
import Error404 from "views/examples/Error404.js"
import AdminInstaCarta from "views/AdminInstaCarta/AdminInstaCarta.js";
import Giftcard from "views/AdminInstaCarta/Giftcard.js";

// others
import {Auth} from 'aws-amplify';
import axios from 'axios';
import config from 'config'
import { toast, Toaster } from "react-hot-toast";

var AWS = require('aws-sdk');

class App extends Component {
    constructor(props) {
      super(props)

      this.state = {
        isAuthenticated: false,
        isAuthenticating: true,
        user: {
          username: "Invitado",
        },
        reloadPending: false,
        productsAndServices: false,
        currentUserTemplate: {
          userURL: "/tarjeta/"
        },
        isViewingUser: false,
        credentials: {
          "accessKeyId": "",
          "secretAccessKey": "",
          "sessionToken": ""
        }
      }
    }

    setAuthStatus = authenticated => {
      this.setState({ isAuthenticated: authenticated});
    }
  
    setUser = user => {
      if(user === null){
        user = {
          username: "Invitado",
        }
      }
      this.setState({user: user});
    }

    setSession = session => {
      this.setState({session: session});
    }

    setReloadPending = bool => {
      this.setState({reloadPending: bool});
    }

    setProductsAndServices = bool => {
      this.setState({productsAndServices: bool});
    }

    setCurrentUserTemplate = object => {
      this.setState({currentUserTemplate: object});
    }

    setIsViewingUser = bool => {
      this.setState({isViewingUser: bool});
    }

    setIconColor = color => {
      this.setState({iconColor: color});
    }

    setJwtToken = jwt => {
      this.setState({jwt: jwt});
    }

    setLoginVariables = (user, jwt, isAuthenticated) => {
      if(user === null){
        user = {
          username: "Invitado",
        }
      }
      this.setState({user: user, jwt: jwt, isAuthenticated: isAuthenticated});
    }

  
    async componentDidMount() {
      try{
        const session = await Auth.currentSession();
        const jwt = await session.getIdToken().getJwtToken()
        const user = await Auth.currentAuthenticatedUser();
        axios.defaults.headers.common['Id-Token'] = jwt;
        this.setLoginVariables(user, jwt, true);
      }catch(error){
        console.log(error);
        this.setAuthStatus(false);
      }
      this.setState({isAuthenticating: false});
    }

    setAWSConfig(){
      // Initialize the Amazon Cognito credentials provider
      AWS.config.region = 'us-west-2'; // Region
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'us-west-2:c0bd0e57-43ce-4c2c-b48e-891b1f5fec7e',
          Logins: {
            'cognito-idp.us-west-2.amazonaws.com/us-west-2_P9GZMefqH': ""
          }
      });
    }

  render() {
      const authProps = {
        isAuthenticated: this.state.isAuthenticated,
        user: this.state.user,
        jwt: this.state.jwt,
        setAuthStatus: this.setAuthStatus,
        setUser: this.setUser,
        setReloadPending: this.setReloadPending,
        reloadPending: this.state.reloadPending,
        productsAndServices: this.state.productsAndServices,
        setProductsAndServices: this.setProductsAndServices,
        currentUserTemplate: this.state.currentUserTemplate,
        setCurrentUserTemplate: this.setCurrentUserTemplate,
        credentials: this.state.credentials,
        setLoginVariables: this.setLoginVariables
      }

      return (
        !this.state.isAuthenticating &&
        <Suspense fallback={<div>Loading...</div>}>
        <div>
          <Toaster
            toastOptions={{
              // Define default options
              // className: '',
              duration: 3000,
              position: "top-center"
              // Default options for specific types
              // success: {
              //   duration: 1500,
              //   theme: {
              //     primary: 'green',
              //     secondary: 'black',
              //   },
              // },
            }}
          />
        </div>
        
        <Router history={this.props.hist} auth={authProps}>
          <Switch>
            <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
            <Route path="/blog-post" render={(props) => <BlogPost {...props} />} />
            <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
            <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />
            <Route path="/e-commerce" render={(props) => <Ecommerce {...props} />} />
            <Route path="/index" render={(props) => <Index {...props} />} />
            <Route
              path="/landing-page"
              render={(props) => <LandingPage {...props} />}
            />
            <Route path="/login-page" render={(props) => <LoginPage {...props} auth={authProps} />} />
            <Route
              path="/nucleo-icons"
              render={(props) => <NucleoIcons {...props} />}
            />
            <Route
              path="/presentation"
              render={(props) => <Presentation {...props} auth={authProps} />}
            />
            <Route
              path="/u/:id"
              render={(props) => <About id={props.match.params.id} /> }
            />
            <Route
              path="/giftcard/:id"
              render={(props) => <Giftcard id={props.match.params.id} {...props} auth={authProps} toast={toast}/> }
            />
            <Route path="/pricing" render={(props) => <Pricing {...props} />} />
            <Route
              path="/product-page"
              render={(props) => <ProductPage {...props} />}
            />
            <Route
              path="/profile-page"
              render={(props) => <ProfilePage {...props} />}
            />
            <Route
              path="/admin"
              render={(props) => <AdminInstaCarta {...props} auth={authProps} toast={toast}/>}
            />
            <Route path="/sections" render={(props) => <Sections {...props} />} />
            <Route path="/sign-up" render={(props) => <SignupPage {...props} />} />
            <Redirect from="/" to="/presentation" />
          </Switch>
        </Router>
        </Suspense>
      );
    }
}  
export default App;

function About(props) {
  const [result, setResult] = React.useState(true);

  React.useEffect(() => {
    const url = `${config.api.read_link}?link_id=${props.id}`
    console.log(url)
    axios.get(url)
    .then(response => {
      if(response.status === 200)
        if(response.data.redirection.active){
          window.location.replace(response.data.redirection.link_to_redirect);
        }
    })
    .catch(error => {
      setResult(false)
      console.log(error)
    })
  }, [props.id])

  // 👇️ redirect to external URL
  

  return (
    <>
      <Error404 pending={result}/>
    </>
  );
}

// ReactDOM.render(
//   <BrowserRouter>
//     <Switch>
      
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById("root")
// );
