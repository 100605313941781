import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import "./landingPage.css"

// core components

function Components() {
  return (
    <>
      <div
        className="section section-components"
        data-background-color="dark-blue"
      >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" xs="12" md="12" xl="12">
              <h2 className="text-center title">
                Revoluciona la forma en la que sirves a tus clientes <br></br>
                <small className="description">
                <b>Diseñado para facilidad de tus usuarios </b>
                </small>
              </h2>
              <h5 className="text-center description landing-page-text">
                <b>
                  Con tu InstaCarta despliega tu Carta a tus clientes con tan solo un toque a su celular.
                  Esto es posible gracias a la tecnologia NFC, lo cual permite una lectura 100% segura de tu Carta
                  a los clientes con tan solo un simple toque.
                </b>
              </h5>
              <div className="space-50"></div>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <div className="card-container first-card">
                <div className="card-component">
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    <div className="front">
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/InstaCarta.png")
                            .default
                        }
                        width="100%"
                        height="100%"
                      ></img>
                    </div>
                  {/* </a> */}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container second-card">
                <div className="card-component">
                  {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                    <div className="front">
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/InstaCarta.png")
                            .default
                        }
                      ></img>
                    </div>
                  {/* </a> */}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container third-card">
                <div className="card-component">
                  {/* <a href="sections.html"> */}
                    <div className="front">
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/InstaCarta.png")
                            .default
                        }
                      ></img>
                    </div>
                  {/* </a> */}
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="card-container fourth-card">
                <div className="card-component">
                  {/* <a href="examples/product-page.html"> */}
                    <div className="front" style={{backgroundColor: "transparent"}}>
                      <img
                        alt="..."
                        src={
                          require("assets/img/presentation-page/InstaCarta.png")
                            .default
                        }
                      ></img>
                    </div>
                  {/* </a> */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Components;
