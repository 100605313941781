import React, { Component } from 'react'

// core components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import DropdownFixedNavbar from 'components/Navbars/DropdownFixedNavbar';
import WhiteNavbar from 'components/Navbars/WhiteNavbar';
import FixedTransparentNavbar from 'components/Navbars/FixedTransparentNavbar';
import "./waves.css"
import "./AdminInstaCarta.css"
import StickyHeadTable from 'components/Tables/StickyHeader';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Redirect } from "react-router-dom";

// custom components
import config from 'config';
import axios from 'axios';
import CreateForm from 'components/InstaCartaAPI/CreateForm';

// Icons
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardControlKeyIcon from '@mui/icons-material/KeyboardControlKey';


export default class AdminInstaCarta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createButton: {
        displayForm: false,
        loading: false,
        success: false
      },
      input: {
        linkToRedirect: ""
      },
      redirections: []
    };
  }
    
  componentDidMount = () => {
    if (this.props.auth.isAuthenticated === true){
      this.handleScan();
    }
  }

  handleChangeCreateButton = (isLoading, isSuccess) => {
    if(typeof(isLoading) === "boolean" && typeof(isSuccess) === "boolean"){
      let createButton = this.state.createButton;
      createButton.loading = isLoading;
      createButton.success = isSuccess;
      this.setState({createButton});
    }
  }

  handleDisplayCreate = () => {
    let createButton = this.state.createButton;
    createButton.displayForm = !createButton.displayForm
    if(createButton.displayForm){
      createButton.color = 'red'
    } else {
      createButton.color = 'success'
    }
    this.setState({createButton: createButton})
  }

  handleInputChange = (e) => {
    let name = e.target.id;
    let value = e.target.value;
    let input = this.state.input;
    input[name] = value;

    this.setState({input: input})
  }

  handleResetCreateForm = () => {
    let input = this.state.input;
    let createButton = this.state.createButton;

    input.linkToRedirect = "";
    createButton.displayForm = false;
    this.setState({input: input, createButton: createButton})
  }

  handleGuardarButton = (setSuccessFunc, setLoadingFunc) => {
    let url = `${config.api.create_link}`

    let data = {
      link_to_redirect: this.state.input.linkToRedirect
    }

    axios.post(url, data)
    .then(response => {
      if(response.status === 201){
        setSuccessFunc(true)
        setLoadingFunc(false)
        this.props.toast.success('Registro creado.')
        this.handleResetCreateForm();
        this.handleScan();
      } else {
        this.props.toast.error('Algo salio mal.')
        setSuccessFunc(false)
        setLoadingFunc(false)
      }
    }).catch(error => {
      if(error.response){
        if(error.response.status === 400){
          this.props.toast.error('La redirección escrita es inválida.')
        }
      }
      setSuccessFunc(false)
      setLoadingFunc(false)
      console.log(error)
    })
  }

  handleRowUpdate = (row) => {
    const url = `${config.api.update_link}`

    const data = {
      link_id: row.link_id,
      link_to_redirect: row.link_to_redirect,
      active: row.active
    }

    const update_promise = axios.put(url, data)
    .then(response => {
      console.log(response)
      if(response.status === 201){
        this.handleScan();
      } else {
        this.props.toast.error('Algo salio mal.')
      }
    }).catch(error => {
      if(error.response){
        if(error.response.status === 400){
          this.props.toast.error('La redirección escrita es inválida.')
        }
      }
      console.log(error)
    })

    this.props.toast.promise(update_promise, {
      loading: 'Actualizando...',
      success: 'Todo Listo!',
      error: 'Algo salio mal.',
    });
  }

  handleRowDelete = (row, setDeleteInProgressFunc) => {
    const url = `${config.api.update_link}`

    const data = {
      link_id: row.link_id,
    }

    const delete_promise = axios.delete(url, {data: data})
    .then(response => {
      setDeleteInProgressFunc(false);
      if(response.status === 202){
        this.handleScan();
      } else {
        this.props.toast.error('Algo salio mal.')
      }
    }).catch(error => {
      setDeleteInProgressFunc(false);
      if(error.response){
        this.props.toast.error('Algo salio mal.')
      }
      console.log(error)
    })

    this.props.toast.promise(delete_promise, {
      loading: 'Eliminando...',
      success: 'Todo Listo!',
      error: 'Algo salio mal.',
    });
  }

  handleScan = () => {
    axios.defaults.headers.common['Id-Token'] = this.props.auth.jwt;
    let url = `${config.api.scan_links}`
    axios.get(url)
    .then(response => {
      if(response.status === 200){
        this.setState({redirections: response.data.redirections})
      }
    }).catch(error => {
      console.log(error)
    })
  }

  render() {
    if (this.props.auth.isAuthenticated === false) {
      return <Redirect to='/' />
    }
    return (
      <>
        <FixedTransparentNavbar {...this.props} />
        <div class="admin-insta-carta-header">
          <div class="admin-insta-carta-inner-header admin-insta-carta-flex">
            <div className='sticky-header-container'>
              <div style={{height: "100px"}}>
              </div>
              <h2>Mis Instacartas</h2>
              <Stack spacing={2} direction="row">
                <Button 
                  variant="text" 
                  style={{color: "white"}}
                  // color='white'
                  onClick={() => {this.handleDisplayCreate()}}>
                  {this.state.createButton.displayForm
                    ? <>Nueva Tarjeta <KeyboardControlKeyIcon/> </> 
                    : <>Nueva Tarjeta <KeyboardArrowDownIcon/> </>
                  }
                </Button>
              </Stack>
              <br></br>
              { this.state.createButton.displayForm && 
                <CreateForm 
                  handleGuardarButton={this.handleGuardarButton} 
                  handleInputChange={this.handleInputChange}
                  linkToRedirect={this.state.input.linkToRedirect}
                  handleChangeCreateButton={this.handleChangeCreateButton}
                />
              }
              <StickyHeadTable 
                rows={this.state.redirections} 
                handleRowUpdate={this.handleRowUpdate}
                handleRowDelete={this.handleRowDelete}
              />
            </div>
            
          </div>
          {/* <!--Header box ends--> */}

          
          {/* <!--Waves--> */}
          <div>
            <svg class="admin-insta-carta-waves" xmlns="http://www.w3.org/2000/svg" 
              viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
              <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
              </defs>
              <g class="admin-insta-carta-parallax">
                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
              </g>
            </svg>
          </div>
        </div>
      </>
    )
  }
}

// xmlns:xlink="http://www.w3.org/1999/xlink"
// xml:space="preserve"
// xmlns:xlink="http://www.w3.org/1999/xlink"

// function AdminInstaCarta(props) {
//   React.useEffect(() => {
//     document.body.classList.add("presentation-page");
//     document.body.classList.add("sidebar-collapse");
//     document.documentElement.classList.remove("nav-open");
//     window.scrollTo(0, 0);
//     document.body.scrollTop = 0;

//     // initialise Rellax for this page
//     if (window.innerWidth >= 991) {
//       setTimeout(function () {
//         new Rellax(".rellax", {
//           center: true,
//         });
//       }, 5000);
//       new Rellax(".rellax-header");
//       new Rellax(".rellax-text");
//     }

//     // we need to add a script for the github buttons
//     let script = document.createElement("script");
//     script.src = "https://buttons.github.io/buttons.js";
//     script.id = "github-buttons-script-id";
//     document.body.appendChild(script);

//     return function cleanup() {
//       document.body.classList.remove("presentation-page");
//       document.body.classList.remove("sidebar-collapse");

//       // we need to remove the script when we change the page
//       script.parentNode.removeChild(script);
//     };
//   });
//   return (
//     <>
//       <ScrollTransparentNavbar {...props} />
//       <div className="wrapper">
//         <PresentationHeader />
//         <Components />
//         <BasicComponents />
//         <Cards />
//         {/* <Content />
//         <Sections />
//         <Examples />
//         <FreeDemo />
//         <Icons />
//         <Image />
//         <Testimonials />
//         <Pricing /> */}
//         <FooterBlack />
//       </div>
//     </>
//   );
// }
