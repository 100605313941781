import React from "react";

function FormErrors(props) {
  if (
    props.formerrors &&
    (props.formerrors.blankfield || props.formerrors.passwordmatch)
  ) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center" style={{color: "white"}}>
          {props.formerrors.passwordmatch
            ? "Las contraseñas no coinciden"
            : ""}
        </div>
        <div className="row justify-content-center help is-danger">
          {props.formerrors.blankfield ? "Todos los campos son requeridos" : ""}
        </div>
        <br></br>
      </div>
    );
  } else if (props.apierrors) {
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center" style={{color: "white"}}>{props.apierrors}</div>
        <br></br>
      </div>
    );
  } else if (props.formerrors && props.formerrors.cognito) {
    
    var spanishErrorMessage = props.formerrors.cognito.message;
    if (props.formerrors.cognito.message === 'All fields are required') 
      spanishErrorMessage = 'Todos los campos son requeridos'
    else if (props.formerrors.cognito.message === 'Incorrect username or password.')
      spanishErrorMessage = 'Usuario o contraseña incorrecto'
    return (
      <div className="error container help is-danger">
        <div className="row justify-content-center" style={{color: "white"}}>
          {spanishErrorMessage}
        </div>
        <br></br>
      </div>
    );
  } else {
    return <div />;
  }
}

export default FormErrors;