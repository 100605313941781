import React from "react";
// javascript library that creates a parrallax effect
// reactstrap components

// core components
import Error404Image from 'assets/img/Error404Robot.png'
import CircularProgress from '@mui/material/CircularProgress';
import "./Error404.css"


/* eslint-disable */
// sections for this page

/* eslint-enable */

function Error404(props) {
  
  return (
    <>{ props.pending
      ? (<center className="center">
        <CircularProgress style={{width: "200px", height: "200px"}}/>
      </center>)

      : (<div>
        <center>
          <h1 style={{fontSize: "150px"}}>404</h1>
          <p style={{fontSize: "30px"}}>Si eres propietario de esta tarjeta por favor contacta a tu administrador.
          </p>
        <img src={Error404Image} width="800px" alt="Error 404"/>
        </center>
      </div>)
      }
    </>
  );
}

export default Error404;


