import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';

// Icons
import EditIcon from '@mui/icons-material/Edit';

export default function UpdateForm(props) {
  const [open, setOpen] = React.useState(false);
  const [fullWidth] = React.useState(true);
  const [maxWidth] = React.useState('md');
  const [linkToRedirect, setLinkToRedirect] = React.useState(props.linkToRedirect);
  const [activeRedirection, setActiveRedirection] = React.useState(props.active);

  // Error validation
  const [invalidLink, setInvalidValidLink] = React.useState(false);
  const [helperText, setHelperText] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setActiveRedirection(props.active);
    setLinkToRedirect(props.linkToRedirect);
    setInvalidValidLink(false);
    setHelperText("");
    setOpen(false);
  };

  const handleGuardar = () => {
    // eslint-disable-next-line
    var httpRegex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    if(linkToRedirect === props.linkToRedirect && activeRedirection === props.active){
      return setOpen(false);
    }
    if(!props.linkToRedirect){
        setInvalidValidLink(true)
        setHelperText("El campo no puede estar vacío.")
    }
    else if(!httpRegex.test(linkToRedirect)){
        setInvalidValidLink(true)
        setHelperText("El formato del link no es correcto, debe incluir (http:// o https://).")
    }
    else {
        setInvalidValidLink(false)
        setHelperText("")
        const row = {
          link_id: props.linkId,
          link_to_redirect: linkToRedirect,
          active: activeRedirection
        }
        props.handleRowUpdate(row);
        setOpen(false);
    }
  }

  const handleClickCheckbox = (e) => {
    setActiveRedirection(e.target.checked)
  }

  return (
    <React.Fragment>
      <EditIcon 
        className="update-buttons" 
        onClick={handleClickOpen}
      />
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Actualizar</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Creado: {props.createdAt} - Visitas: {props.viewCount}
          </DialogContentText>
          {/* <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            
          </Box> */}
        <Box sx={{ flexGrow: 1 }} style={{padding: "10px"}}>
          <Grid container spacing={2} >
            <Grid item xs={12} md={12} xl={12}>
              <TextField
                id="main-url-input"
                label="URL"
                defaultValue={props.url}
                InputProps={{
                    readOnly: true,
                }}
                disabled={true}
                size="small"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={11} xl={10}>
              <TextField 
                id="linkToRedirect" 
                label="Redirigir a:" 
                variant="outlined"
                size="small"
                style={{backgroundColor: "white"}}
                defaultValue={linkToRedirect}
                value={linkToRedirect}
                onChange={(e) => {setLinkToRedirect(e.target.value)}}
                fullWidth
                error={invalidLink}
                helperText={helperText}
              />
            </Grid>
            <Grid item xs={12} md={1} xl={2}>
              <FormControlLabel
                value="start"
                control={
                  <Checkbox label="Activo" 
                    checked={activeRedirection} 
                    defaultChecked={activeRedirection} 
                    onChange={handleClickCheckbox}
                  />
                }
                label="Activo"
                labelPlacement="start"
              />
              
            </Grid>
          </Grid>
        </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleGuardar}>Guardar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}