/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import './ScrollTransparentNavbar.css'
import attributesExist from "components/CustomFunctions/validation";

import {Auth} from 'aws-amplify';

async function handleLogOut(auth){
  if (auth.isAuthenticated === true) {
    try{
      await Auth.signOut({ global: true });
      auth.setAuthStatus(false);
      auth.setUser(null);

      window.localStorage.clear();
      window.location.reload(false);
      
    }catch(error){
      console.log('Error: Error al cerrar session: ' + error.message);

      window.localStorage.clear();
      window.location.reload(false);
    };
  }
  
}

function FixedTransparentNavbar(props) {
  var path = attributesExist(props, ["location","pathname"])? props.location.pathname : false
  var textColor = "black"
  if(path){
    if(path === "/admin"){
      textColor = "white"
    }
  }
  var isAuthenticated = attributesExist(props, ["auth","isAuthenticated"])? props.auth.isAuthenticated : false
  var user = attributesExist(props, ["auth","user","username"])? props.auth.user.username : "Invitado"
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="navbar-absolute navbar-transparent" expand="lg">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand style={{color: textColor, fontWeight: "bold"}} to="/presentation" tag={Link} id="navbar-brand">
              InstaCarta
            </NavbarBrand>
            <UncontrolledTooltip target="navbar-brand">
              Menú Digital
            </UncontrolledTooltip>
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span style={{backgroundColor: textColor}} className="navbar-toggler-bar top-bar"></span>
              <span style={{backgroundColor: textColor}} className="navbar-toggler-bar middle-bar"></span>
              <span style={{backgroundColor: textColor}} className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="ml-auto" id="ceva" navbar>
              {/* <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink1"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="now-ui-icons design_app"></i>
                  <p>Components</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink1" right>
                  <DropdownItem to="/" tag={Link}>
                    <i className="now-ui-icons design_image"></i>
                    Presentation
                  </DropdownItem>
                  <DropdownItem to="/index" tag={Link}>
                    <i className="now-ui-icons business_chart-pie-36"></i>
                    All components
                  </DropdownItem>
                  <DropdownItem
                    href="https://demos.creative-tim.com/now-ui-kit-pro-react/#/documentation/introduction?ref=nuk-pro-react-fixed-transparent-navbar"
                    target="_blank"
                    target="_blank"
                  >
                    <i className="now-ui-icons design_bullet-list-67"></i>
                    Documentation
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i
                    aria-hidden={true}
                    className="now-ui-icons files_paper"
                  ></i>
                  <p>Sections</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem to="/sections#headers" tag={Link}>
                    <i className="now-ui-icons shopping_box"></i>
                    Headers
                  </DropdownItem>
                  <DropdownItem to="/sections#features" tag={Link}>
                    <i className="now-ui-icons ui-2_settings-90"></i>
                    Features
                  </DropdownItem>
                  <DropdownItem to="/sections#blogs" tag={Link}>
                    <i className="now-ui-icons text_align-left"></i>
                    Blogs
                  </DropdownItem>
                  <DropdownItem to="/sections#teams" tag={Link}>
                    <i className="now-ui-icons sport_user-run"></i>
                    Teams
                  </DropdownItem>
                  <DropdownItem to="/sections#projects" tag={Link}>
                    <i className="now-ui-icons education_paper"></i>
                    Projects
                  </DropdownItem>
                  <DropdownItem to="/sections#pricing" tag={Link}>
                    <i className="now-ui-icons business_money-coins"></i>
                    Pricing
                  </DropdownItem>
                  <DropdownItem to="/sections#testimonials" tag={Link}>
                    <i className="now-ui-icons ui-2_chat-round"></i>
                    Testimonials
                  </DropdownItem>
                  <DropdownItem to="/sections#contact-us" tag={Link}>
                    <i className="now-ui-icons tech_mobile"></i>
                    Contact Us
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  href="#pablo"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                >
                  <i
                    aria-hidden={true}
                    className="now-ui-icons design_image"
                  ></i>
                  <p>Examples</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem tag={Link} to="/about-us">
                    <i className="now-ui-icons business_bulb-63"></i>
                    About-us
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/blog-post">
                    <i className="now-ui-icons text_align-left"></i>
                    Blog Post
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/blog-posts">
                    <i className="now-ui-icons design_bullet-list-67"></i>
                    Blog Posts
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/contact-us">
                    <i className="now-ui-icons location_pin"></i>
                    Contact Us
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/landing-page">
                    <i className="now-ui-icons education_paper"></i>
                    Landing Page
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/login-page">
                    <i className="now-ui-icons users_circle-08"></i>
                    Login Page
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/pricing">
                    <i className="now-ui-icons business_money-coins"></i>
                    Pricing
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/e-commerce">
                    <i className="now-ui-icons shopping_shop"></i>
                    Ecommerce Page
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/product-page">
                    <i className="now-ui-icons shopping_bag-16"></i>
                    Product Page
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/profile-page">
                    <i className="now-ui-icons users_single-02"></i>
                    Profile Page
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/sign-up">
                    <i className="now-ui-icons tech_mobile"></i>
                    Signup Page
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              {isAuthenticated ? 
                (<NavItem tag={Link} to="/login-page">
                  {/* <Button
                    className="nav-link btn-default NavbarWidthLabelColors">
                      <i className="now-ui-icons users_single-02"></i>
                      <p style={{marginLeft: "5px"}}>{user}</p>
                  </Button> */}
                  <UncontrolledDropdown nav>
                <DropdownToggle
                  caret
                  color="default"
                  data-toggle="dropdown"
                  id="navbarDropdownMenuLink"
                  nav
                  onClick={(e) => e.preventDefault()}
                  style={{color: textColor}}
                >
                  <i
                    aria-hidden={true}
                    className="now-ui-icons users_single-02"
                  ></i>
                  <p style={{color: textColor, fontWeight: "bold"}}>{user}</p>
                </DropdownToggle>
                <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                  <DropdownItem tag={Link} to="/admin" onClick={() => {
                    document.documentElement.classList.toggle("nav-open");
                    setCollapseOpen(!collapseOpen);
                  }}>
                    <i className="now-ui-icons ui-1_settings-gear-63"></i>
                    Administrar mi InstaCarta
                  </DropdownItem>
                  <DropdownItem onClick={() => handleLogOut(props.auth)}>
                    <i className="now-ui-icons users_single-02"></i>
                    Salir
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
                </NavItem>)
              : (
              <NavItem tag={Link} to="/login-page">
                <Button
                  color="white"
                  style={{color: textColor, fontWeight: "bold"}}
                  className="nav-link btn-default NavbarWidthLabelColors">
                    <i className="now-ui-icons users_single-02"></i>
                    <p style={{marginLeft: "5px", color: textColor, fontWeight: "bold"}}>Iniciar Sesión</p>
                </Button>
              </NavItem>
              )}

              
               
              <NavItem>
                  <Button
                  style={{borderColor: 'solid', border: "1px solid black"}}
                    className="nav-link btn-default"
                    color={'neutral'}
                    href="https://api.whatsapp.com/send?phone=526641976413&text=Vengo%20por%20mi%20InstaCarta"
                    target="_blank"
                    >
                      <img 
                    alt="..." 
                    src={
                          require("assets/img/whatsapp.png")
                          .default
                        }
                    width="24px"
                    height="24px"
                    >
                    </img>
                    <p style={{marginLeft: "10px"}}>¡La quiero!</p>
                  </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default FixedTransparentNavbar;
