import React, { Component } from 'react'
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import config from 'config';

// Custom Components
import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";
import "./waves.css"
import Error404 from 'views/examples/Error404';

export default class Giftcard extends Component {

    constructor(props){
        super(props);
        this.state = {
            giftcard_id: "",
            balance: "0",
            notFound: false

        }

    }

    //reactjs onComponentdidmount?
    componentDidMount = () => {
        this.handleGiftcardRead();
    }

    handleGiftcardRead = () => {
        axios.defaults.headers.common['Id-Token'] = this.props.auth.jwt;
        const url = `${config.api.read_giftcard}?link_id=${this.props.id}`

        axios.get(url)
        .then(response => {
        if(response.status === 200)
            if(response.data.redirection.balance){
                this.setState({balance: response.data.redirection.balance})
            } else {
                this.setState({notFound: true});
            }
        })
        .catch(error => {
            this.setState({notFound: true});
            console.log(error)
        })
        }

    render() {
        return (
            <>
                <ScrollTransparentNavbar {...this.props} />
                { this.state.notFound === false?
                    <div class="admin-insta-carta-header">
                        <div class="admin-insta-carta-inner-header admin-insta-carta-flex">
                            <div className='sticky-header-container'>
                                <div style={{height: "0px"}}>
                                </div>
                                <Paper elevation={3}>
                                    <Grid sx={{ flexGrow: 1, marginTop: "100px" }} container spacing={2}>
                                        <Grid item xs={12} style={{minHeight: "200px"}}>
                                            <h2>Tarjeta de Regalo</h2>

                                            <img style={{marginBottom: "20px"}} alt="logo" height="100px" width="auto" src="https://www.eluniversal.com.mx/sites/default/files/2019/05/10/la_churrasqueria_brasilena_menu_el_universal.jpg" itemprop="contentUrl"></img>
                                            <h2>${this.state.balance} MXN</h2>

                                            <p >Para hacer valida tu tarjeta de regalo presentala fisicamente en el establecimiento.</p>
                                        </Grid>
                                    </Grid>
                                </Paper>
                            </div>
                        </div>
                        {/* <!--Header box ends--> */}

            
                        {/* <!--Waves--> */}
                        <div>
                            <svg class="admin-insta-carta-waves" xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g class="admin-insta-carta-parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                            </svg>
                        </div>
                
                    </div>
                :
                <>
                    <Error404></Error404>
                </>
                }
            </>
        )
    }
}


{/* <Paper
                sx={{
                    p: 2,
                    margin: 'auto',
                    maxWidth: 500,
                    flexGrow: 1,
                    backgroundColor: (theme) =>
                    theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                }}
                > */}
                            {/* <Grid item>
                                <ButtonBase sx={{ width: 128, height: 128 }}>
                                    <Img alt="complex" src="/static/images/grid/complex.jpg" />
                                </ButtonBase>
                            </Grid>
                            <Grid item xs={12} sm container>
                                <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs>
                                    <Typography gutterBottom variant="subtitle1" component="div">
                                        Standard license
                                    </Typography>
                                    <Typography variant="body2" gutterBottom>
                                        Full resolution 1920x1080 • JPEG
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        ID: 1030114
                                    </Typography>
                                    </Grid>
                                    <Grid item>
                                    <Typography sx={{ cursor: 'pointer' }} variant="body2">
                                        Remove
                                    </Typography>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography variant="subtitle1" component="div">
                                    $19.00
                                    </Typography>
                                </Grid>
                            </Grid> */}

                {/* </Paper> */}