/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function Footer() {
  return (
    <>
      <footer className="footer">
        <Container>
          <nav>
            <ul>
              <li>
                <a
                  href="http://azuradevs.com.mx/"
                  target="_blank"
                >
                  AzuraDevs
                </a>
              </li>
              <li>
                <a
                  href="http://azuradevs.com.mx/"
                  target="_blank"
                >
                  Acerca de nosotros
                </a>
              </li>
              {/* <li>
                <a
                  href="http://blog.creative-tim.com?ref=nuk-pro-react-footer"
                  target="_blank"
                >
                  Blog
                </a>
              </li> */}
            </ul>
          </nav>
          <div className="copyright" id="copyright">
            © {new Date().getFullYear()}, Diseñado por{" "}
            <a
              href="http://azuradevs.com.mx/"
              target="_blank"
            >
              AzuraDevs
            </a>
            .
          </div>
        </Container>
      </footer>
    </>
  );
}

export default Footer;
