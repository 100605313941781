
import React from "react";
import Amplify from 'aws-amplify';
import config from './config';
import App from './App'
import { render } from 'react-dom';
import { createBrowserHistory } from 'history';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

// import "assets/css/material-dashboard-react.css?v=1.9.0";

//Toast notifications
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const history = createBrowserHistory();

const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES,
);

//Needed for Toast to work
toast.configure();

// Amplify Configure
Amplify.configure({
  Auth: {
    mandatorySignId: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

let app = document.getElementById('root');
if (app) {
    // 1. Set up the browser history with the updated location
    // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(window.location.hash) || [])[1];

	if (path) {
    //window.location = `http://localhost:3000${path}`
    history.replace(path);
	}

    // 2. Render our app
	render(
    <ThemeProvider theme={theme}>
      <App hist={history}/>
    </ThemeProvider>, app
  );
}