import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import UpdateForm from 'components/InstaCartaAPI/UpdateForm';
import DeleteConfirmationDialog from 'components/InstaCartaAPI/DeleteConfirmationDialog';
import useWindowDimensions from 'components/CustomFunctions/useWindowDimensions';
import Grid from '@mui/material/Grid';

// CSS
import "./StickyHeader.css"

const format_date = (value) => {
  value = new Date(value);
  return `${value.getDate()}/${value.getMonth()}/${value.getFullYear()}`
}

const format_active = (value) => {
  if(value){
    return <Checkbox label="Sí" disabled checked={true} />
  } else {
    return <Checkbox label="No" disabled checked={false} />
  }
}

const columns = [
  { 
    id: 'url',
    label: 'Enlace', 
    minWidth: 170, 
    format: (value) => {
      return <a href={value} target="_blank" rel="noreferrer">{value}</a>
    } 
  },
  { id: 'link_to_redirect', 
    label: 'Redireccionar a', 
    minWidth: 170,
  },
  {
    id: 'link_id',
    label: 'ID de Tarjeta',
    minWidth: 170,
  },
  {
    id: 'created_at',
    label: 'Creado',
    minWidth: 170,
    format: (value) => {
      value = new Date(value);
      return `${value.getDate()}/${value.getMonth()}/${value.getFullYear()}`
    },
    align: "center"
  },
  {
    id: 'active',
    label: 'Activa',
    minWidth: 100,
    format: (value) => {
      if(value){
        return <Checkbox label="Sí" disabled checked={true} />
      } else {
        return <Checkbox label="No" disabled checked={false} />
      }
    },
    align: "center"
  },
  {
    id: 'view_count',
    label: 'Vistas',
    minWidth: 100,
    align: "center"
  },
  {
    id: 'actualizar',
    label: 'Actualizar',
    minWidth: 100,
    align: "center"
  },
  {
    id: 'eliminar',
    label: 'Eliminar',
    minWidth: 100,
    align: "center"
  },
];

export default function StickyHeadTable(props) {
  const { width } = useWindowDimensions();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden'}}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            { width > 600 &&
              <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>}

          </TableHead>
          <TableBody>
            {props.rows.length > 0 &&
              props.rows
              // .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                row["actualizar"] = <UpdateForm 
                                      url={row["url"]} 
                                      linkId={row["link_id"]}
                                      linkToRedirect={row["link_to_redirect"]} 
                                      active={row["active"]} 
                                      createdAt={format_date(row["created_at"])} 
                                      viewCount={row["view_count"]}
                                      handleRowUpdate={props.handleRowUpdate}
                                    />
                                    
                row["eliminar"] = <DeleteConfirmationDialog 
                                    url={row["url"]}
                                    linkId={row["link_id"]}
                                    handleRowDelete={props.handleRowDelete}
                                  />

                return (
                  <>
                    {/* Desktop View */}
                    { width > 600 ?
                      (<TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>)
                    :
                    (<>
                    {/* Mobile view */}
                    <TableRow hover role="checkbox" tabIndex={-1} key={`${row.code}_mobile`}>
                      
                        {columns.map((column) => {
                          const value = row[column.id];
                          if(column.id === "actualizar" || column.id === "eliminar" || column.id === "created_at" || column.id === "active"){
                            return;
                          }
                          return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                              <TableCell key={column.id} align={column.align}>
                                {column.label}
                              </TableCell>
                              <TableCell key={`${column.id}_value`} align={"left"} style={{
                                whiteSpace: 'normal',
                                wordWrap: 'break-word',
                                maxWidth: width-width/3
                              }}>
                                {column.format
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableRow>
                    <Grid container spacing={0} style={{marginTop: "10px", marginBottom: "10px"}}>
                      <Grid item xs={6} style={{marginTop: "10px"}}>
                          Creado: {format_date(row["created_at"])}
                      </Grid>
                      <Grid item xs={6}>
                          Activa: {format_active(row["active"])}
                      </Grid>
                      {/* <Grid item xs={12} style={{marginTop: "10px"}}>
                          Vistas: {row["view_count"]}
                      </Grid> */}
                    </Grid>
                    <Grid container spacing={0} style={{marginTop: "15px", marginBottom: "10px"}}>
                      <Grid item xs={6}>
                          {row["eliminar"]}
                      </Grid>
                      <Grid item xs={6}>
                          {row["actualizar"]}
                      </Grid>
                    </Grid>
                    <div style={{backgroundColor: "black", height: "5px"}}></div>
                    </>
                    )}
                  </>
                );
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
      {props.rows.length === 0 &&
      <Box sx={{ width: '100%' }} md={{ width: '100%' }} xl={{ width: '100%' }}>
        <LinearProgress />
      </Box>}
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}