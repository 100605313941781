import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Cards() {
  return (
    <>
      <div className="section section-cards" style={{marginTop: "-100px"}}>
        <Container>
          <Row>
            <Col className="text-center ml-auto mr-auto" md="12">
              <div className="section-description">
                <h2 className="title">Todo en uno</h2>
                <h6 className="category">¿Por qué conformarte con uno?</h6>
                <h5 className="description">
                  <b>
                    Complementa tu menú QR con un menú de contacto para tus clientes, 
                    diferenciate y provee diferentes formas de consultar tu menú.
                  
                  <br></br>
                  <br></br>
                  Podrás actualizar tu menú digital desde nuestra página, para que nunca tengas problemas con nuevos platillos.
                  <br></br>
                  ¡No mas reemplazos de QR!
                </b>

                </h5>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="images-container">
                <div className="image-container1 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/qr-code.png").default
                    }
                  ></img>
                </div>
                <div className="image-container2 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/nfccell.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container3 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/menumuestra.jpg").default
                    }
                  ></img>
                </div>
                {/* <div className="image-container4 mr-1">
                  <img
                    alt="..."
                    src={
                      require("assets/img/presentation-page/InstaCarta.jpg").default
                    }
                  ></img>
                </div>
                <div className="image-container5">
                  <img
                    alt="..."
                    src={
                      require("assets/img/presentation-page/card4.jpg").default
                    }
                  ></img>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Cards;
