/*eslint-disable*/
import React from "react";
import './PresentationHeader.css'

// reactstrap components

// core components

function PresentationHeader() {
  return (
    <>
      <div className="page-header clear-filter" style={{backgroundColor: "white"}}>
        <div className="rellax-header rellax-header-sky" data-rellax-speed="-4" style={{height: "100%"}} id="PresentationHeader">
          <div
            className="page-header-image"
            id="animate-area"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/presentation-page/InstaCartaFullBg.jpg")
                  .default +
                ")",
                backgroundSize: "cover",
                // backgroundRepeat: "no-repeat"
            }}
          ></div>
        </div>
        {/* <div
          className="rellax-header rellax-header-buildings"
          data-rellax-speed="0"
        >
          <div
            className="page-header-image page-header-city"
            style={{
              backgroundImage:
                "url(" +
                require("assets/img/presentation-page/nuk-pro-buildings.png")
                  .default +
                ")",
            }}
          ></div>
        </div> */}
        <div className="rellax-text-container rellax-text">
          <h1 style={{font: "black", textcolor: "black"}} className="h1-seo" id="troll1" data-rellax-speed="-1" >
            Menú Digital
          </h1>
          {/* <div className="pro">PRO</div> */}
        </div>
        {/* <h3 className="h3-description rellax-text" data-rellax-speed="-1">
          A beautiful premium Bootstrap 4 UI Kit.
        </h3> */}
        <h6
          className="category category-absolute rellax-text"
          data-rellax-speed="-1"
          style={{color: "black"}}
        >
          Designed by{" "}
          {/* <a href="http://invisionapp.com/?ref=creativetim" target="_blank"> */}
            {/* <img
              alt="..."
              className="invision-logo"
              src={require("assets/img/invision-white-slim.png").default}
            ></img> */}
          {/* </a> */}
          AzuraDevs
          {/* . Coded by{" "}
          <a
            href="https://www.creative-tim.com?ref=nuk-pro-react-presentation-header"
            target="_blank"
          >
            <img
              alt="..."
              className="creative-tim-logo"
              src={require("assets/img/creative-tim-white-slim2.png").default}
            ></img>
          </a>
          . */}
        </h6>
      </div>
    </>
  );
}

export default PresentationHeader;
