/*Checks if child attributes of an object exist
PARAMS:
  mainObject: object: {}, required
  array: array: ["auth", "isAuthenticated"], required*/
function attributesExist(mainObject, array) {
let prevElement = mainObject
let result = true

array.forEach(element => {
    if (prevElement !== undefined && Object.keys(prevElement).length !== 0 && prevElement !== null) {
        try {
            if(prevElement[element])
            prevElement = prevElement[element]
            else
            result = false
        } catch (error) {
            result = false
        }
        } else {
            result = false
        }
    });

    return result
}

export default attributesExist;